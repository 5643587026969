// Models
import { EAthleteStatusToPortuguese, TAthleteStatus } from 'models'

// React
import { FC } from 'react'

// Misc
import { calculateAge } from 'utils/functions'

// Components
import { Avatar, LinkButton } from 'heeds-ds'
import { StatusTag } from 'components'
import { buttonClickTracking } from 'utils/tracking'
import InfoLoader from './loader'

enum EGender {
  M = 'Masculino',
  F = 'Feminino',
  O = 'Outro',
}

type Props = {
  info?: Record<string, string>
  name: string
  photo?: string
  status?: TAthleteStatus
  profileUrl?: string
  loading?: boolean
}

const Info: FC<Props> = ({
  info = {},
  name,
  photo,
  status,
  profileUrl = '',
  loading,
}) => {
  const { birthdate, gender, instagram } = info

  if (loading) {
    return <InfoLoader />
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col gap-4 bg-surface md:flex-row md:rounded-[8px] md:border md:border-border-input md:p-4">
        <Avatar
          className="mx-auto md:mx-0"
          name={name}
          scale="xLarge"
          src={photo}
        />
        <div className="flex flex-col items-center md:items-start">
          <div className="hidden md:flex">
            <StatusTag status={status} margin="0 0 8px 0" />
          </div>
          <p
            className="mb-2 break-words text-center text-sh3 font-bold text-text md:text-left md:text-copy3"
            data-testid="name"
          >
            {name}
          </p>
          <div className="flex w-full items-center">
            <p className="hidden w-full text-copy4 font-semibold text-text-subdued md:flex md:text-text">
              {birthdate && `${calculateAge(birthdate)} anos • `}
              {EGender[gender as keyof typeof EGender]}
              {instagram && ' • ' + instagram}
            </p>
            <p className="w-full text-copy4 text-text-subdued md:hidden md:text-text">
              {birthdate && `${calculateAge(birthdate)} anos • `}
              {EGender[gender as keyof typeof EGender]}
            </p>
          </div>
          <div className="flex pb-4 pt-2 text-text-subdued md:hidden md:text-text">
            <p className="text-copy4">{instagram}</p>
          </div>
          <div className="flex rounded-full bg-badge-greenBackground px-4 py-2 md:hidden">
            <p className="text-copy4 font-semibold text-badge-greenText">
              {EAthleteStatusToPortuguese[status as TAthleteStatus]}
            </p>
          </div>
        </div>
      </div>
      <LinkButton
        variation="borderless"
        to={profileUrl}
        state={{ from: 'profile' }}
        size="small"
        track={buttonClickTracking}
        trackName="athlete_profile_edit"
        className="flex capitalize md:hidden"
      >
        Editar perfil
      </LinkButton>
    </div>
  )
}

export default Info
